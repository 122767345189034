import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Spin, Upload, UploadProps, Select } from 'antd';
import { useDetailAcrylicPlate } from 'src/api/useDetailAcrylicPlate';
import NotFoundPage from 'src/pages/notFoundPage';
import ImgCrop from 'antd-img-crop';
import IconUpload from 'src/assets/icon/IconUpload';
import IconDelete from 'src/assets/icon/IconDelete';
import IconAddForm from 'src/assets/icon/IconAddForm';
import IconSubForm from 'src/assets/icon/IconSubForm';
import { NumericFormat } from 'react-number-format';
import { AnyPtrRecord } from 'dns';
import { AcrylicPlateType, useAddAcrylic } from 'src/api/useAddAcrylic';
import { useMutation, useQueryClient } from 'react-query';
import { AcrylicPlateEditType, useEditAcrylic } from 'src/api/useEditAcrylic';
import { useCheckAcrylicSize } from 'src/api/useCheckAcrylicSize';
import { STATUS_CODE_NOT_PERMISS_DELETE_ACRYLIC } from 'src/assets/constants';
import ModalConfirm from 'src/components/common/modal/modalConfirm';

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const AcrylicPlateCreateEditComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [imageUrlArr, setImageUrlArr] = useState<Array<any>>(['']);
  const [imageUrlBottle, setImageUrlArrBottle] = useState<string>('');
  const [imageMessErrorArr, setImageMessErrorArr] = useState<Array<any>>(['']);
  const [imageFile, setImageFile] = useState<Array<any>>([{}]);
  const [imageFileBottle, setImageFileBottle] = useState<File>();
  const [plateRemoveArr, setPlateRemoveArr] = useState<Array<any>>([]);
  const titleAcrylicCurrent = Form.useWatch('title', antForm);
  const imageBottleCurrent = Form.useWatch('image', antForm);
  const acrylicPlatesCurrent = Form.useWatch('acrylic_plate_sizes', antForm);
  const [isOpenModalAlert, setIsOpenModalAlert] = useState<boolean>(false);
  const [ratioValue, setRatioValue] = useState([]);

  const queryClient = useQueryClient();
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailAcrylicPlate(Number(id));

  const handleGoBack = () => {
    history(id ? `/product/acrylic-plates/${id}` : '/product/acrylic-plates');
  };

  const { isLoading: isLoadingAcrylic, mutate: onCreateAcrylic } = useMutation(
    (param: AcrylicPlateType) => useAddAcrylic(param),
    {
      onSuccess: () => {
        message.success('香水ラベル追加に成功しました');
        history('/product/acrylic-plates');
        antForm.resetFields();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const { isLoading: isLoadingEdit, mutate: onEditAcrylic } = useMutation(
    (param: AcrylicPlateEditType) => useEditAcrylic(param, Number(id)),
    {
      onSuccess: () => {
        message.success('香水ラベル編集に成功しました ');
        history(`/product/acrylic-plates`);
        antForm.resetFields();
        queryClient.clear();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const { isLoading: isCheckAcrylicSizeToDelete, mutate: onCheckAcrylicSizeToDelete } = useMutation(
    ({ idAcrylicSize, remove, item }: { idAcrylicSize: any; remove?: any; item: any }) =>
      useCheckAcrylicSize(idAcrylicSize),
    {
      onSuccess: (data, variable: any) => {
        onDeleteAcrylicSizeForm(variable?.item?.name, variable?.remove, variable?.item);
      },
      onError: (e: any) => {
        if (e?.response?.status === STATUS_CODE_NOT_PERMISS_DELETE_ACRYLIC) {
          setIsOpenModalAlert(true);
        }
      },
    }
  );

  const handleCreateEditAcrylic = async () => {
    const valueForm = antForm.getFieldsValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const newAcrylicList = valueForm?.acrylic_plate_sizes?.map((item: any, index: any) => {
      return {
        ...item,
        image: imageFile?.[index],
      };
    });
    if (!onCheckDisableBtnSubmit()) {
      if (id) {
        const newAcrylicListEditting = valueForm?.acrylic_plate_sizes?.map((item: any, index: any) => {
          let newItemPlate = item;
          if (Object.keys(imageFile?.[index])?.length !== 0) {
            return {
              ...item,
              image: imageFile?.[index],
            };
          }
          return newItemPlate;
        });
        onEditAcrylic({
          title: valueForm?.title,
          image: imageFileBottle,
          acrylic_plate_sizes: newAcrylicListEditting,
          acrylic_plate_size_delete_ids: plateRemoveArr,
        });
      } else {
        onCreateAcrylic({ title: valueForm?.title, image: imageFileBottle, acrylic_plate_sizes: newAcrylicList });
      }
    }
  };

  const onEditImageUrlArr = (url?: any, indexImageEditing?: any) => {
    const newImageUrlArr = imageUrlArr?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return url;
      }
      return item;
    });
    setImageUrlArr(newImageUrlArr);
    const newImagePlateForm = acrylicPlatesCurrent?.map((item: any, indexPlate: any) => {
      if (indexPlate === indexImageEditing) {
        return {
          ...item,
          image: url,
        };
      }
      return item;
    });
    antForm.setFieldsValue({ acrylic_plate_sizes: newImagePlateForm });
  };

  const onEditImageUrlArrBottle = (url?: any) => {
    setImageUrlArrBottle(url);
    antForm.setFieldsValue({ image: url });
  };

  useEffect(() => {
    const listRatioValue = acrylicPlatesCurrent?.map((item: any, index: any) => {
      return { [index]: item.aspect_ratio };
    });

    setRatioValue(listRatioValue);
  }, [acrylicPlatesCurrent]);

  const onChangeRatioAcrylic = (e: any, index: any) => {
    const valueRatio = e.target.value
    const newRatioData: any = [...ratioValue];

    if (newRatioData[index]) {
      newRatioData[index] = valueRatio;
    }
    setRatioValue(newRatioData);
    const newImagePlateForm = acrylicPlatesCurrent?.map((item: any, indexPlate: any) => {
      if (indexPlate === index) {
        return {
          ...item,
          aspect_ratio: valueRatio,
          height: '',
          width: '',
        };
      }
      return item;
    });
    antForm.setFieldsValue({ acrylic_plate_sizes: newImagePlateForm });
    antForm.resetFields([['acrylic_plate_sizes', index, 'width']]);
    antForm.resetFields([['acrylic_plate_sizes', index, 'height']]);
  };

  const onChangeHeightAcrylic = (e: any, indexEditing?: any) => {
    const valueInput = e?.target?.value;
    const aspectRatio = ratioValue[indexEditing][indexEditing];
    const valueWidth = valueInput ? Math.round(Number(valueInput) / aspectRatio) : '';
    const newImagePlateForm = acrylicPlatesCurrent?.map((item: any, indexPlate: any) => {
      if (indexPlate === indexEditing) {
        return {
          ...item,
          height: valueInput,
          width: valueWidth,
        };
      }
      return item;
    });
    antForm.setFieldsValue({ acrylic_plate_sizes: newImagePlateForm });
    antForm.validateFields([['acrylic_plate_sizes', indexEditing, 'width']]);
  };

  const onChangeWidthAcrylic = (e: any, indexEditing?: any) => {
    const valueInput = e?.target?.value;
    const aspectRatio = ratioValue[indexEditing][indexEditing];
    const valueHeight = valueInput ? Math.round(Number(valueInput) * aspectRatio) : '';
    const newImagePlateForm = acrylicPlatesCurrent?.map((item: any, indexPlate: any) => {
      if (indexPlate === indexEditing) {
        return {
          ...item,
          width: valueInput,
          height: valueHeight,
        };
      }
      return item;
    });
    antForm.setFieldsValue({ acrylic_plate_sizes: newImagePlateForm });
    antForm.validateFields([['acrylic_plate_sizes', indexEditing, 'height']]);
  };

  const onEditImageFileArr = (file: File, indexImageEditing: any) => {
    const newImageFileArr = imageFile?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return file;
      }
      return item;
    });
    setImageFile(newImageFileArr);
  };

  const onEditImageFileArrBottle = (file: File) => {
    setImageFileBottle(file);
  };

  onEditImageFileArrBottle

  const onEditImageMessErrorArr = (error?: any, indexImageEditing?: any) => {
    const newImageMessErrorArr = imageMessErrorArr?.map((item: any, indexUrl: any) => {
      if (indexImageEditing === indexUrl) {
        return error;
      }
      return item;
    });
    setImageMessErrorArr(newImageMessErrorArr);
  };

  const beforeUpload = async (file: any, index: any) => {
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(jpg|jpeg|png)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        onEditImageMessErrorArr('ファイルサイズが5MBを超えています', index);
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        onEditImageUrlArr(url, index);
        onEditImageFileArr(file, index);
        onEditImageMessErrorArr('', index);
      }
    } else {
      onEditImageMessErrorArr('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください', index);
      return;
    }
    return false;
  };

  const beforeUploadBottle = async (file: any) => {
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(jpg|jpeg|png)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        onEditImageMessErrorArr('ファイルサイズが5MBを超えています');
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        onEditImageUrlArrBottle(url);
        onEditImageFileArrBottle(file)
      }
    } else {
      onEditImageMessErrorArr('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
      return;
    }
    return false;
  };

  const handleDeleteThumbnail = (index: AnyPtrRecord | any) => {
    onEditImageUrlArr('', index);
    onEditImageMessErrorArr('ファイルをアップロードしてください', index);
  };

  const handleDeleteThumbnailBottle = () => {
    onEditImageUrlArrBottle('')
  };

  const validateTitle = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`香水ラベル名を入力してください`);
    } else if (value?.trim()?.length > 50) {
      callback(`香水ラベル名は50文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const validateSize = (rule: any, value: string, callback: any, indexPlate: any) => {
    const hasDuplicatePlate = !!acrylicPlatesCurrent
      ?.filter((item: any, index: any) => index !== indexPlate)
      ?.filter((item: any, indexFilter: any) => item?.size_title === value)?.length;
    if (!value?.trim()) {
      callback(`サイズを入力してください`);
    } else if (value?.trim()?.length > 5) {
      callback(`サイズは5文字以内で入力してください`);
    } else if (hasDuplicatePlate) {
      callback(`指定のサイズは既に使用されています。確認し、再度入力してください`);
    } else {
      callback();
    }
  };

  function onCheckDisableInputSize(value: any) {
    if ((value === 0 || value) && acrylicPlatesCurrent) {
      let isDisableBtnSubmitCurrent = true;
      const aspectRatio = Number(acrylicPlatesCurrent[value]?.aspect_ratio);
      if (aspectRatio >= 0.5 && aspectRatio <= 2) {
        isDisableBtnSubmitCurrent = false;
      }
      return isDisableBtnSubmitCurrent;
    }
  }

  const onCheckDisableBtnSubmit = () => {
    let isDisableBtnSubmitCurrent = false;
    acrylicPlatesCurrent?.forEach((item: any) => {
      if (
        Number(item?.height || 0) === 0 ||
        !item?.image ||
        Number(item?.price || 0) === 0 ||
        Number(item?.price || 0) < 50 ||
        Number(item?.shipping_fee || 0) === 0 ||
        !item?.size_title ||
        Number(item?.width || 0) === 0 ||
        Number(item?.aspect_ratio || 0) === 0
      ) {
        isDisableBtnSubmitCurrent = true;
      }
    });
    const hasNoImage = !!imageUrlArr?.filter((item: any) => !item)?.length;
    const hasErrorImage = !!imageMessErrorArr?.filter((item: any) => item)?.length;
    const validateErrorValueList =
      isDisableBtnSubmitCurrent || !imageBottleCurrent || !titleAcrylicCurrent || hasNoImage || (hasErrorImage && hasNoImage);
    return validateErrorValueList;
  };

  const onNavigateListTemplate = () => {
    history('/product/templates');
  };

  const handleCloseModalAlert = () => {
    setIsOpenModalAlert(false);
  };

  const onDeleteAcrylicSizeForm = (index: any, remove: any, item: any) => {
    if (acrylicPlatesCurrent?.[index]?.id) {
      setPlateRemoveArr([...plateRemoveArr, acrylicPlatesCurrent?.[index]?.id]);
    }
    remove(item?.name);
    setImageUrlArr(imageUrlArr?.filter((item: any, indexFilter: any) => indexFilter !== index));
    setImageFile(imageFile?.filter((item: any, indexFilter: any) => indexFilter !== index));
    setImageMessErrorArr(imageMessErrorArr?.filter((item: any, indexFilter: any) => indexFilter !== index));
  };

  const uploadButton = (
    <button className="upload-file" type="button">
      <IconUpload />
      <p className="text-upload">ファイルアップロード</p>
    </button>
  );

  useEffect(() => {
    if (detailData) {
      const newImageUrlArr = detailData?.acrylic_plate_sizes?.map((item: any) => {
        return item?.image_url;
      });
      setImageUrlArr(newImageUrlArr);
      setImageUrlArrBottle(detailData?.image_url)
      const lengthListAcrylicData = detailData?.acrylic_plate_sizes?.length;
      if (lengthListAcrylicData) {
        const newArrImageErrorFill = new Array(lengthListAcrylicData).fill('');
        const newArrImageFileFill = new Array(lengthListAcrylicData).fill({});
        setImageMessErrorArr(newArrImageErrorFill);
        setImageFile(newArrImageFileFill);
      }
      antForm.setFieldsValue(detailData);
    }
  }, [detailData]);

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail && id) {
    return <NotFoundPage />;
  }

  const handleChangeValue = (allvalues: any) => {
    allvalues?.acrylic_plate_sizes?.forEach((item: any, index: any) => {
      if (item?.size_title) {
        antForm.validateFields([['acrylic_plate_sizes', index, 'size_title']]);
      }
    });
  };

  const validateNumber = (rule: any, value: string, callback: any, errorNotValue: any) => {
    if (!String(value)?.trim()) {
      callback(errorNotValue);
    } else if (Number(String(value)?.trim()) === 0) {
      callback(`0以外の値を入力してください`);
    } else {
      callback();
    }
  };

  const validatePrice = (rule: any, value: string, callback: any, errorNotValue: any) => {
    if (!String(value)?.trim()) {
      callback(errorNotValue);
    } else if (Number(String(value)?.trim()) === 0) {
      callback(`0以外の値を入力してください`);
    } else if (Number(String(value)?.trim()) < 50) {
      callback(`最小請求金額は50円以上です。`);
    } else {
      callback();
    }
  };

  const validateRatio = (rule: any, value: string, callback: any, errorNotValue: any) => {
    const trimmedValue = String(value)?.trim();
    if (!trimmedValue) {
      callback(errorNotValue);
    } else if (Number(trimmedValue) < 0.5 || Number(trimmedValue) > 2) {
      callback('比率は0.5以上2以下である必要があります。');
    } else {
      callback();
    }
  };

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">{id ? '香水ラベルの編集' : '香水ラベルの追加'}</span>
            </div>

            <div></div>
          </div>
          <Form
            form={antForm}
            className="form-create w-[900px] mx-auto"
            layout="vertical"
            autoComplete="off"
            onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
          >
            <div className="pb-[12px]">
              <div className="mb-[16px] mr-[54px]">
                <Form.Item
                  required
                  label="香水ラベル名"
                  name="title"
                  rules={[{ validator: (rule, value, callback) => validateTitle(rule, value, callback) }]}
                >
                  <Input className="input-form" placeholder="香水ラベル名を入力してください" />
                </Form.Item>
              </div>
              <div className="mb-[16px] mr-[54px] bg-blue p-4">
                <Form.Item
                  required
                  label="Image Preview"
                  name="image"
                >
                  <div className="view-img-crop mt-3">
                    <ImgCrop
                      showGrid
                      showReset
                      aspect={2 / 3}
                      modalTitle="サムネイル画像のトリミング"
                      modalOk="トリミング"
                      modalCancel="キャンセル"
                      resetText="リセット"
                      minZoom={1}
                      maxZoom={2}
                    >
                      <Upload
                        listType="picture-card"
                        showUploadList={false}
                        multiple={false}
                        maxCount={1}
                        beforeUpload={(file) => beforeUploadBottle(file)}
                        className="upload-file-image upload-file-plate-preview"
                      >
                        {imageUrlBottle ? (
                          <div className="thumbnail-upload-wrap">
                            <img src={imageUrlBottle} alt="thumbnail" className="thumbnail-upload" />
                          </div>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                    {imageUrlBottle && (
                      <button className="icon-delete-plate-2" onClick={() => handleDeleteThumbnailBottle()} type="button">
                        <IconDelete />
                      </button>
                    )}
                  </div>
                </Form.Item>
              </div>
              <Form.List name="acrylic_plate_sizes" initialValue={[{}]}>
                {(acrylicPlateList, { add, remove }) => (
                  <div className="">
                    {acrylicPlateList?.map((item: any, index: any) => {
                      return (
                        <div className="view-form-info-plate" key={item?.key}>
                          <div className="mt-[30px] bg-blue p-[20px]">
                            <div className="mb-[30px]">
                              <Form.Item required label="画像のサムネイル" name={[item?.name, 'image']}>
                                <div className="view-img-crop">
                                  <ImgCrop
                                    showGrid
                                    showReset
                                    rotationSlider
                                    aspect={4 / 5}
                                    modalTitle="サムネイル画像のトリミング"
                                    modalOk="トリミング"
                                    modalCancel="キャンセル"
                                    resetText="リセット"
                                  >
                                    <Upload
                                      listType="picture-card"
                                      showUploadList={false}
                                      multiple={false}
                                      maxCount={1}
                                      beforeUpload={(file) => beforeUpload(file, index)}
                                      className="upload-file-image upload-file-plate"
                                    >
                                      {imageUrlArr?.[index] ? (
                                        <img src={imageUrlArr?.[index]} alt="thumbnail" className="thumbnail-upload" />
                                      ) : (
                                        uploadButton
                                      )}
                                    </Upload>
                                  </ImgCrop>
                                  {imageUrlArr?.[index] && (
                                    <button
                                      className="icon-delete-plate"
                                      onClick={() => handleDeleteThumbnail(index)}
                                      type="button"
                                    >
                                      <IconDelete />
                                    </button>
                                  )}
                                </div>
                              </Form.Item>
                              {imageMessErrorArr?.[index] && (
                                <p className="invalid-feedback whitespace-pre-line">{imageMessErrorArr?.[index]}</p>
                              )}
                            </div>
                            <div className="mb-[50px] flex items-top gap-[30px]">
                              <Form.Item
                                required
                                label="サイズ"
                                name={[item?.name, 'size_title']}
                                rules={[
                                  { validator: (rule, value, callback) => validateSize(rule, value, callback, index) },
                                ]}
                                className="w-[257px] error-form-acrylic"
                              >
                                <Input className="input-form" placeholder="例：S" />
                              </Form.Item>
                              <Form.Item
                                required
                                label="単価（円）"
                                name={[item?.name, 'price']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validatePrice(rule, value, callback, `単価を入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  placeholder="例：500"
                                  maxLength={10}
                                />
                              </Form.Item>
                              <Form.Item
                                required
                                label="配送料（円）"
                                name={[item?.name, 'shipping_fee']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validateNumber(rule, value, callback, `配送料を入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  placeholder="例：250"
                                  maxLength={10}
                                />
                              </Form.Item>
                            </div>
                            <div className="mb-[30px] flex items-center gap-[30px]">
                              <Form.Item
                                required
                                label="比率"
                                name={[item?.name, 'aspect_ratio']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validateRatio(rule, value, callback, `比率を入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={1}
                                  decimalSeparator="."
                                  placeholder="例：1.5"
                                  maxLength={3}
                                  onChange={(e: any) => onChangeRatioAcrylic(e, index)}
                                />
                              </Form.Item>
                              <Form.Item
                                required
                                label="高さ（mm）"
                                name={[item?.name, 'height']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validateNumber(rule, value, callback, `高さを入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input disabled:bg-[#eaeaea]"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  placeholder="例：100"
                                  maxLength={10}
                                  onChange={(e: any) => onChangeHeightAcrylic(e, index)}
                                  disabled={onCheckDisableInputSize(index)}
                                />
                              </Form.Item>
                              <Form.Item
                                required
                                label="幅（mm）"
                                name={[item?.name, 'width']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validateNumber(rule, value, callback, `幅を入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input disabled:bg-[#eaeaea]"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  placeholder=" 例：60"
                                  maxLength={10}
                                  onChange={(e: any) => onChangeWidthAcrylic(e, index)}
                                  disabled={onCheckDisableInputSize(index)}
                                />
                              </Form.Item>
                              {/* <Form.Item
                                required
                                label="厚さ"
                                name={[item?.name, 'thickness']}
                                className="w-[257px] error-form-acrylic"
                                rules={[
                                  {
                                    validator: (rule, value, callback) =>
                                      validateNumber(rule, value, callback, `厚さを入力してください`),
                                  },
                                ]}
                              >
                                <NumericFormat
                                  pattern="[0-9]*"
                                  className="number-input"
                                  style={{ width: '100%' }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  placeholder="例：3"
                                  maxLength={10}
                                />
                              </Form.Item> */}
                            </div>
                          </div>
                          <div className="flex flex-col w-[42px] ml-[16px]">
                            {(index > 0 || (index === 0 && acrylicPlateList?.length > 1)) && (
                              <div
                                className={`button-add-item-form`}
                                onClick={async (e: any) => {
                                  const idAcrylicSize = acrylicPlatesCurrent?.[index]?.id;
                                  if (idAcrylicSize) {
                                    onCheckAcrylicSizeToDelete({ idAcrylicSize, remove, item });
                                  } else {
                                    onDeleteAcrylicSizeForm(index, remove, item);
                                  }
                                }}
                              >
                                <IconSubForm />
                              </div>
                            )}
                            {(index === acrylicPlateList?.length - 1 ||
                              (index === 0 && acrylicPlateList?.length === 1)) && (
                              <div
                                className={`button-add-item-form ${index > 0 ? 'mt-[40px]' : ''}`}
                                onClick={(e: any) => {
                                  add(item?.name);
                                  setImageUrlArr([...imageUrlArr, '']);
                                  setImageFile([...imageFile, {}]);
                                  setImageMessErrorArr([...imageMessErrorArr, '']);
                                }}
                              >
                                <IconAddForm />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Form.List>
            </div>
            <div className="footer-form flex mr-[54px]">
              <Button className="button-form mr-[16px]" onClick={handleGoBack}>
                キャンセル
              </Button>
              <Button
                disabled={onCheckDisableBtnSubmit()}
                className="button-form button-form-Submit"
                onClick={handleCreateEditAcrylic}
                loading={isLoadingAcrylic || isLoadingEdit}
              >
                <span></span>
                {location.pathname?.includes('edit') ? 'OK' : '追加'}
              </Button>
            </div>
          </Form>
          <ModalConfirm
            isShowModal={isOpenModalAlert}
            onCancel={handleCloseModalAlert}
            onOk={onNavigateListTemplate}
            content="削除できません"
            subContentPrefix="連携しているテンプレートがあります。削除する場合は該当のテンプレートを編集・削除にしてください。"
            loadingButtonOk={isCheckAcrylicSizeToDelete}
            isClose={false}
            isIcon={false}
            textOk="テンプレート一覧へ"
          />
        </div>
      )}
    </>
  );
};

export default AcrylicPlateCreateEditComponent;
